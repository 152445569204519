import styled from "styled-components";
import { HeroButtonText } from "../HeroLandingPage/Hero.styled";
import { MenuItem, MenuList, Paper, Popper } from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import { LiaCalendarAlt } from "react-icons/lia";

export const MainHeading = styled.h1`
  @media only screen and (min-width: 1920px) {
    display: flex;
    justify-content: center;
    padding-top: 250px;
    text-align: center;
    font-family: Didot;
    font-style: normal;
    font-weight: 100;
    font-size: 48px;
    line-height: 60.67px;
    color: #000000;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: flex;
    justify-content: center;
    font-family: "Didot";
    font-size: 42px;
    line-height: 53px;
    font-weight: 100;
    text-align: center;
    padding-top: 140px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    display: flex;
    justify-content: center;
    font-family: "Didot";
    font-size: 32px;
    line-height: 40.45px;
    font-weight: 400;
    text-align: center;
    padding-top: 140px;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    font-family: "Didot";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    padding-top: 140px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: flex;
    justify-content: center;
    font-family: "Didot";
    font-size: 32px;
    line-height: 40.45px;
    font-weight: 400;
    text-align: center;
    padding-top: 140px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    display: flex;
    justify-content: center;
    font-family: "Didot";
    font-size: 32px;
    line-height: 40.45px;
    font-weight: 400;
    text-align: center;
    padding-top: 140px;
  }
`;

// Div of Unit Type, beds, price from, price to, rent or sale, community or area

export const PrimaryButtonsDiv = styled.div`
  row-gap: 20px;
  column-gap: 20px;
  @media only screen and (min-width: 1920px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 80%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 80%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 80%;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 80%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    width: 80%;
  }
`;

export const SecondaryButtonsDiv = styled.div`
  display: flex;
  gap: 1%;
  width: 90%;
  overflow-x: auto;
  overflow-y: visible;
  margin: auto;
  height: 40px;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (min-width: 2100px) {
    justify-content: center;
  }
`;

export const SecondaryButtonDivContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  margin: auto;
  padding: 2vh 0;
  @media only screen and (min-width: 1920px) {
    width: 80%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 80%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 90%;
  }
  @media only screen and (max-width: 900px) {
    width: 90%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 90%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 90%;
  }
`;

// Search Buttons

export const SearchButton = styled.button`
  text-align: center;
  background-color: #12284c;
  border: 1px solid #12284c;
  color: #ffffff;
  border-radius: 90px;
  transition: 0.8s;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  overflow: hidden;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  transition: 0.8s;
  &:hover {
    background-color: #004876;
  }
  @media only screen and (min-width: 1920px) {
    width: 157px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 157px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 250px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 298px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 250px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 250px;
    height: 40px;
  }
`;

// Sale and Rent toggle button

export const CheckBoxWrapper = styled.div`
  display: flex;
  border-radius: 90px;
  overflow-y: hidden;
  align-items: center;
  box-sizing: border-box;
  background-color: rgba(238, 242, 244, 0.5);
  border: 1px solid #12284c;
  padding: 0px;
  backdrop-filter: blur(10px);
  @media only screen and (min-width: 1920px) {
    width: 155px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 121px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 121px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 121px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 121px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 121px;
    height: 40px;
  }
`;

export const SaleButton = styled.button`
  box-sizing: border-box;
  margin-left: 8px;
  padding: 0px;
  border-radius: 90px;
  overflow-y: hidden;
  border: 1px solid #12284c;
  background-color: #12284c;
  color: #ffffff;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  transition: 0.8s;
  text-transform: "none";
  @media only screen and (min-width: 1920px) {
    width: 71.59px;
    height: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (max-width: 900px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 53.86px;
    height: 30px;
  }
`;
export const RentButton = styled.button`
  padding: 0px;
  margin-left: 15px;
  overflow-y: hidden;
  overflow-x: hidden;
  border-radius: 90px;
  font-family: Montserrat;
  margin-right: 7px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-transform: none;
  background-color: rgba(238, 242, 244, 0.5);
  border: 1.5px solid rgba(238, 242, 244, 0.5);
  color: #c0c0c0;
  transition: 0.8s;
  @media only screen and (min-width: 1920px) {
    width: 71.59px;
    height: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (max-width: 900px) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 53.86px;
    height: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 53.86px;
    height: 30px;
  }
`;

// Input Field of "community or area"
export const BoxField = styled.div`
  box-sizing: border-box;
  background: transparent;
  overflow: hidden;
  border: ${(props) => props.border};
  backdrop-filter: blur(10px);
  border-radius: 34px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0px;
  &::placeholder {
    color: #c0c0c0;
    font-size: 13px;
  }
  @media only screen and (min-width: 1920px) {
    width: 360px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 224px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 250px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 240px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 308.73px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 308.73px;
    height: 40px;
  }
`;

export const DateBoxField = styled(BoxField)`
  @media only screen and (min-width: 1920px) {
    width: 300px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 200px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 150px;
  }
  @media only screen and (max-width: 900px) {
    width: 150px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 150px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 150px;
  }
`;

export const BoxDiv = styled(BoxField)`
  display: ${(props) => (props.active ? "block" : "none")};
  position: absolute;
  height: auto;
  border: ${(props) => props.border};
`;
export const BoxText = styled(HeroButtonText)`
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
  color: ${(props) => props.color};
  font-size: 13px;
  &:hover {
    background: ${(props) => props.hoverback};
    color: ${(props) => props.hovercolor};
  }
`;
export const InputField = styled.input`
  color: ${(props) => props.color};
  background-color: transparent;
  max-width: 300px;
  height: 50.45px;
  outline: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px;
  line-height: 150%;
  border: 1px solid transparent;
  &::placeholder {
    color: #c0c0c0;
    font-size: 13px;
  }
  &:active {
    background-color: transparent;
  }

  @media only screen and (min-width: 1900px) {
    width: 410px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1900px) {
    width: 274px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 308.73px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 240px;
    height: 40px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 308.73px;
    height: 40px;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 308.73px;
    height: 40px;
  }
`;

// Div for Unit Type button
// Dropdown button for Unit Type only

export const BoxItem = styled.div`
  box-sizing: border-box;
  overflow-y: hidden;
  background-color: transparent;
  border: ${(props) => props.border};
  backdrop-filter: blur(10px);
  border-radius: 34px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0px;
  color: ${(props) => props.color};
  transition: 0s !important;
  @media only screen and (min-width: 1920px) {
    width: 264.17px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 197px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 198.74px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 123px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 198.74px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 198.74px;
    height: 40px;
  }
`;

export const DropDownMenu = styled.button`
  color: #c0c0c0;
  overflow: hidden;
  background-color: transparent;
  text-align: left;
  margin-left: 20px;
  width: 300px;
  height: 50.45px;
  outline: none;
  border: transparent !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px;
  line-height: 150%;
  font-size: 13px;
  &:active {
    background-color: transparent;
  }
  &::selection {
    color: #000000;
  }
  @media only screen and (min-width: 1920px) {
    width: 264.17px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 197px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 198.74px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 123px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 198.74px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 198.74px;
    height: 40px;
  }
`;

// Div and Button for Beds, price to, price from

export const BoxItemBeds = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
  background-color: rgba(238, 242, 244, 0.5) !important;
  border: 1px solid #12284c;
  backdrop-filter: blur(10px);
  border-radius: 34px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0px;
  color: #12284c;
  @media only screen and (min-width: 1920px) {
    width: 179.47px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 165px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 165px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 165px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 165px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 165px;
    height: 40px;
  }
`;
export const DropDownBeds = styled.button`
  color: #c0c0c0;
  background-color: transparent;
  overflow: hidden;
  text-align: left;
  margin-left: 20px;
  outline: none;
  border: 1px solid rgba(238, 242, 244, 0.5);
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px;
  line-height: 150%;
  font-size: 13px;
  &:active {
    background-color: transparent;
  }
  @media only screen and (min-width: 1920px) {
    width: 179.47px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 160px;
    height: 40px;
  }
`;

export const BoxItemPriceFrom = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
  background-color: transparent;
  border: ${(props) => props.border};
  backdrop-filter: blur(10px);
  border-radius: 34px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0px;
  color: ${(props) => props.color};
  @media only screen and (min-width: 1920px) {
    width: 179.47px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 160px;
    height: 40px;
  }
`;
export const DropDownPriceFrom = styled.button`
  color: #c0c0c0;
  background-color: transparent;
  overflow: hidden;
  text-align: left;
  margin-left: 20px;
  outline: none;
  border: transparent;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px;
  line-height: 150%;
  font-size: 13px;
  &:active {
    background-color: transparent;
  }
  @media only screen and (min-width: 1920px) {
    width: 179.47px;
    height: 40px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (max-width: 900px) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 160px;
    height: 40px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 160px;
    height: 40px;
  }
`;

export const BoxItemRecentlyAdded = styled.div`
  box-sizing: border-box;
  position: relative;
  overflow-y: hidden;
  background-color: transparent;
  min-width: fit-content;
  border: none;
  backdrop-filter: blur(10px);
  border-radius: 34px;
  display: flex;
  align-items: center;
  text-transform: none;
  padding: 0px;
  color: #000000;
`;
export const DropDownMenuRecentlyAdded = styled.button`
  color: #000000;
  background-color: transparent;
  overflow: hidden;
  text-align: left;
  outline: none;
  min-width: fit-content;
  border: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500px;
  line-height: 150%;
  @media only screen and (min-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 12px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 18px;
  }
`;

// all secondary buttons
export const SecondaryButton = styled.button`
  box-sizing: border-box;
  background-color: transparent;
  border-radius: 40px;
  margin-right: 15px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
  line-height: 127%;
  font-weight: 400;
  min-width: fit-content;
  border: ${(props) =>
    props.active ? "1.5px solid #000000" : "1px solid #decdad"};
  padding: 10px 40px;
  &:hover {
    border: 1px solid #000000;
  }
  &:focus {
    border: 1.5px solid #000000;
  }
`;

export const Address = styled.img`
  height: 24.83px;
  width: 16.67px;
  margin-left: 15px;
  margin-right: 10px;
`;

export const Arrows = styled.img`
  height: 24px;
  width: 12px;
`;

export const Preferences = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  margin: auto;
  margin-top: 45px;
  justify-content: space-between;
`;

export const PreferencesDivOne = styled.div`
  min-width: fit-content;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 150%;
  align-items: center;
  letter-spacing: 0.04em;
`;

export const PreferenceDivTwo = styled.div`
  align-items: center;
  display: flex;
  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  h1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    min-width: fit-content;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #000000;
  }
`;

export const Link = styled.a`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  min-width: fit-content;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #000000;
`;

export const LinkOne = styled.a`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  min-width: fit-content;
  line-height: 127%;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #004876;
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
  @media only screen and (min-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 18px;
  }

  @media only screen and (min-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 18px;
  }
`;

export const LinkTwo = styled.a`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  text-decoration-line: underline;
  color: #004876;
`;

export const LineImage = styled.img`
  width: 57px;
  height: 50px;
`;

export const BoxBodyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  width: min(90%, 1720px);
`;

export const PreferencesSmallScreen = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;


export const ProjectSearchCarddiv = styled.div`
  background-color: #ffffff;
    box-shadow: 0px 0px 15px 5px rgb(161 161 161 / 19%);
  border-radius: 10px;
  border: transparent;
  @media only screen and (min-width: 1920px) {
    max-width: 47.5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    max-width: 47.5%;
  }
  @media only screen and (min-width: 700px) and (max-width: 1300px) {
    max-width: 47.5%;
  }
  @media only screen and (max-width:700px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    max-width: 100%;
  }
`;

export const ProjectSearchCardContainer = styled.div`
  margin: auto;
  margin-bottom: 20px;
  @media only screen and (min-width: 1920px) {
    display: flex;
    justify-content: center;
    margin-top: 65.41px;
    flex-wrap: wrap;
    width: min(90%, 1720px);
    row-gap: 50px;
    column-gap: 5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: flex;
    justify-content: center;
    margin-top: 65.41px;
    flex-wrap: wrap;
    width: 90%;
    row-gap: 50px;
    column-gap: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 90%;
    row-gap: 50px;
    column-gap: 30px;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    width: 100%;
    row-gap: 50px;
    margin-top: 20px;
  }
`;


export const PropertyCardCointer = styled.div`
  margin: auto;
  margin-bottom: 20px;
  @media only screen and (min-width: 1920px) {
    display: flex;
    justify-content: center;
    margin-top: 65.41px;
    flex-wrap: wrap;
    width: min(90%, 1720px);
    row-gap: 50px;
    column-gap: 5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    display: flex;
    justify-content: center;
    margin-top: 65.41px;
    flex-wrap: wrap;
    width: 90%;
    row-gap: 50px;
    column-gap: 50px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 90%;
    row-gap: 50px;
    column-gap: 30px;
  }
  @media only screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    row-gap: 50px;
    width: 120%;
    margin-top: 20px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    width: 90%;
    row-gap: 50px;
     flex-direction: column;
    column-gap: 30px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 40px;
    width: 90%;
    row-gap: 50px;
    column-gap: 30px;
  }
`;
export const PropertyCarDiv = styled.div`
  background-color: #ffffff;
  
  /* margin: auto; */
  @media only screen and (min-width: 1920px) {
    width: 47.5%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 47.5%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 90%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 47.5%;
  }
  @media only screen and (min-device-width: 900px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 47.5%;
  }
`;
export const ProjectCardDiv = styled.div`
background-color: #ffffff;
  box-shadow: 0px 0px 15px 5px rgb(161 161 161 / 19%);
  border-radius: 10px;
  border: transparent;
  margin: auto;
  @media only screen and (min-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    min-width: 31.6%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    min-width: 47.5%;
  }
  @media only screen and (max-width: 900px) {
    min-width: 100%;
  }
  @media only screen and (max-width: 400px) {
    min-width: 100%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    min-width: 47.5%;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    min-width: 47.5%;
  }
`
export const FooterPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  padding-bottom: 100px;
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media only screen and (max-width: 900px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
`;

export const FooterHead = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #313131;
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 900px) {
    font-size: 13px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    font-size: 18px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    font-size: 18px;
  }
`;
export const DisplayText = styled.h3`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 150%;
  text-align: center !important;
  letter-spacing: 0.04em;
  color: #313131;
`;

export const PreferenceHead = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
`;

export const PaginationCard = styled.div`
  display: flex;
  row-gap: 3px;
  align-items: center;
  align-content: center;
  justify-content: center;
  @media only screen and (min-width: 1920px) {
    margin-top: 150px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    margin-top: 140px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 60px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    margin-top: 80px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    margin-top: 80px;
  }
`;

export const ArrowButton = styled.button`
  border: none;
  background: transparent;
`;

export const PaperCSS = styled(Paper)`
  background-color: ${(props) => props.menuback};
  color: ${(props) => props.menucolor};
`;

export const PopperBedCSS = styled(Popper)`
  position: relative;
  overflow: hidden;
  height: auto;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  box-sizing: border-box;
  background-color: ${(props) => props.menuback};
  border-radius: 20px;
  border: ${(props) => props.border} !important;
  transition: none;
  backdrop-filter: blur(10px);
  @media only screen and (min-width: 1920px) {
    width: 179.47px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 160px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 160px;
  }
  @media only screen and (max-width: 900px) {
    width: 160px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 160px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 160px;
  }
`;

export const PopperCSS = styled(Popper)`
  position: relative;
  overflow: hidden;
  height: auto;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  box-sizing: border-box;
  background-color: ${(props) => props.menuback};
  border-radius: 20px;
  border: ${(props) => props.border};
  transition: none;
  backdrop-filter: blur(10px);
  @media only screen and (min-width: 1920px) {
    width: 264.17px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 197px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 198.74px;
  }
  @media only screen and (max-width: 900px) {
    width: 123px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 198.74px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 198.74px;
  }
`;

export const MenuItemCss = styled(MenuItem)`
  &:hover {
    background-color: ${(props) => props.menuitembackgroundhover};
    border-radius: 20px;
    color: ${(props) => props.menuitemcolor};
  }
`;

export const MenuListCSS = styled(MenuList)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;


export const CalendarIcon = styled(LiaCalendarAlt)`
  color: ${(props) => props.color};
  margin-left: 10px;
  margin-right: 10px;
  @media only screen and (min-width: 1920px) {
    width: 40px;
    height: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 40px;
    height: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 30px;
    height: 15px;
  }
  @media only screen and (max-width: 900px) {
    width: 30px;
    height: 15px;
  }
  @media only screen and (max-width: 400px) {
    width: 30px;
    height: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 30px;
    height: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 30px;
    height: 15px;
  }
`;


export const LocationIcon = styled(CiLocationOn)`
  color: ${(props) => props.color};
  margin-left: 10px;
  margin-right: 10px;
  @media only screen and (min-width: 1920px) {
    width: 40px;
    height: 30px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1920px) {
    width: 40px;
    height: 30px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1300px) {
    width: 30px;
    height: 15px;
  }
  @media only screen and (max-width: 900px) {
    width: 30px;
    height: 15px;
  }
  @media only screen and (max-width: 400px) {
    width: 30px;
    height: 10px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) {
    width: 30px;
    height: 25px;
  }
  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    width: 30px;
    height: 15px;
  }
`;
