import React, { useEffect, useState } from "react";
import {
  PressCenterSection,
  PressCenterSectionContainer,
  PressCenterText,
  PressContainer,
  PressFilterButton,
  PressFilterContainer,
  PressFilterScrollContainer,
  PressPageNavContainer,
  ResetButton,
} from "./PressCenter.styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPressCenter } from "../../redux/slices/pressCenter/pressCenter";
import PressReleaseCard from "./PressReleaseCard";
import BlogCard from "./BlogCard";
import BrochuerPopUp from "../PopUps/BrochuerPopUp";
import Modal from "@mui/material/Modal";
// import PodcastCard from "./PodcastCard";
import WebinarCard from "./WebinarCard";
import MarketUpdate from "./MarketUpdate";
import { BoxStyle } from "../PropertyListing.js/PropertyListing.styled";
import PodcastCard from "./PodcastCard";

const PressCenterComponent = () => {
  const Location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [property, setProperty] = useState(Location.pathname);
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const handleClick = (item) => {
    setProperty(item);
    setActive(true);
    setTimeout(() => setActive(false), 500);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);
  const fetchHandler = () => {
    const pressObject = {
      property: property.split("/")[1],
      page: page,
      filter: filter,
    };
    dispatch(getPressCenter(pressObject));
  };
  useEffect(() => {
    fetchHandler();
    // if (property === '/market_update') setFilter('Q1_2023')
  }, []);
  useEffect(() => {
    fetchHandler();
  }, [property, filter, page]);

  const handleOpenPdf = () => {
    setOpen(true);
  };

  const closeOpenPdf = () => {
    setOpen(false);
  };

  const setDownload = (item) => {
    setCount(item);
  };

  const { pressCenterData, pageCount } = useSelector(
    (state) => state.presentReducer
  );
  // const meta = useSelector((state)=> state.presentReducer);
  const checkActive = (value) => {
    if (property === value) return true;
    else return false;
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const newData = pressCenterData[count]
    ? pressCenterData[count].attributes.Document
    : "";

  useEffect(() => {
    handleClick(Location.pathname);
  }, [Location.pathname]);

  return (
    <PressCenterSection>
      <PressCenterSectionContainer>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <BoxStyle>
            <BrochuerPopUp
              open={open}
              setOpen={setOpen}
              close={closeOpenPdf}
              parameter="Market Update"
              brochure={newData}
              parameterone="market update"
              button="Download"
              msg="The message is received from market update"
              para="Like 10,000+ subscribers, stay up to date with the latest
              developments in real estate"
            />
          </BoxStyle>
        </Modal>

        <PressCenterText
          active={checkActive("/press_release")}
          onClick={() => handleClick("/press_release")}
        >
          PRESS RELEASES
        </PressCenterText>
        <PressCenterText
          active={checkActive("/blog")}
          onClick={() => handleClick("/blog")}
        >
          BLOG
        </PressCenterText>

        <PressCenterText
          active={checkActive("/podcast")}
          onClick={() => handleClick("/podcast")}
        >
          PODCAST
        </PressCenterText>

        <PressCenterText
          active={checkActive("/market_update")}
          onClick={() => handleClick("/market_update")}
        >
          MARKET UPDATES
        </PressCenterText>

        {/* {width > 900 && (
          <PressCenterText active={checkActive('/webinar')} onClick={() => handleClick("/webinar")}>
            WEBINARS
          </PressCenterText>
        )} */}
      </PressCenterSectionContainer>

      {property !== "/market_update" && (
        <PressFilterContainer>
          {width < 1129 && <KeyboardArrowLeftIcon />}
          <PressFilterScrollContainer>
            <PressFilterButton
              border={filter === "investment_opportunities"}
              onClick={() => setFilter("investment_opportunities")}
            >
              Invest Opportunities
            </PressFilterButton>
            <PressFilterButton
              border={filter === "news"}
              onClick={() => setFilter("news")}
            >
              News
            </PressFilterButton>
            <PressFilterButton
              border={filter === "events"}
              onClick={() => setFilter("events")}
            >
              Events
            </PressFilterButton>
            <PressFilterButton
              border={filter === "featured_articles"}
              onClick={() => setFilter("featured_articles")}
            >
              Featured Articles
            </PressFilterButton>
            <PressFilterButton
              border={filter === "media"}
              onClick={() => setFilter("media")}
            >
              Media
            </PressFilterButton>
          </PressFilterScrollContainer>
          {width < 1129 && <KeyboardArrowRightIcon />}
        </PressFilterContainer>
      )}
      {property === "/market_update" && (
        <PressFilterContainer>
          {width < 1129 && <KeyboardArrowLeftIcon />}
          <PressFilterScrollContainer>
            <PressFilterButton
              border={filter === "Q1_2023"}
              onClick={() => setFilter("Q1_2023")}
            >
              Quater-1 2023
            </PressFilterButton>
            <PressFilterButton
              border={filter === "Q2_2023"}
              onClick={() => setFilter("Q2_2023")}
            >
              Quater-2 2023
            </PressFilterButton>
            <PressFilterButton
              border={filter === "Q3_2023"}
              onClick={() => setFilter("Q3_2023")}
            >
              Quater-3 2023
            </PressFilterButton>
            <PressFilterButton
              border={filter === "Q4_2023"}
              onClick={() => setFilter("Q4_2023")}
            >
              Quater-4 2023
            </PressFilterButton>
            <ResetButton onClick={() => setFilter("")}>RESET</ResetButton>
          </PressFilterScrollContainer>
          {width < 1129 && <KeyboardArrowRightIcon />}
        </PressFilterContainer>
      )}
      <PressContainer active={active}>
        {property === "/press_release" &&
          pressCenterData.map((item) => {
            return <PressReleaseCard key={item.id} data={item} />;
          })}
        {property === "/blog" &&
          pressCenterData.map((item) => {
            return <BlogCard key={item.id} data={item} />;
          })}
        {property === "/podcast" &&
          pressCenterData.map((item) => {
            return <PodcastCard key={item.id} data={item} />;
          })}
        {property === "/market_update" &&
          pressCenterData.map((item, index) => {
            return (
              <MarketUpdate
                key={item.id}
                data={item}
                open={handleOpenPdf}
                close={closeOpenPdf}
                index={index}
                download={setDownload}
              />
            );
          })}
        {property === "/webinar" &&
          pressCenterData.map((item) => {
            return <WebinarCard key={item.id} data={item} />;
          })}
      </PressContainer>
      <PressPageNavContainer>
        {(() => {
          let td = [];
          for (let i = 1; i <= pageCount; i++) {
            td.push(i);
          }
          return td.map((t, id) => {
            return (
              <button key={id} border={page === t} onClick={() => setPage(t)}>
                {t}
              </button>
            );
          });
        })()}
      </PressPageNavContainer>
    </PressCenterSection>
  );
};

export default PressCenterComponent;
