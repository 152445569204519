import React, { useState } from "react";
import SubscribeImage from "../../assets/images/brochuer.png";
import { useDispatch } from "react-redux";
import validator from "validator";
import {
  BoxStyle,
  EmailError,
  HeadingMain,
  ImageStyle,
  InLine,
  InputContainerBrochuer,
  InputEmail,
  ParaDown,
  ParaMain,
  SendButton,
  UpdatesBox,
  UpdatesContainer,
} from "./subscribe.styled";
import { AiOutlineClose } from "react-icons/ai";
import { postContactUs } from "../../redux/slices/contactUs/contactUsSlices";
import {BsFillEyeFill} from "react-icons/bs";
import { showSnackBar } from "../../redux/slices/snackbarSlice/snackbarSlice";

const BrochuerPopUp = ({
  setOpen,
  brochure,
  parameter,
  button,
  msg,
  para
}) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const dispatch = useDispatch();
  const snackbarObject = {
    type: "",
    message: "",
    open: false,
  };

  const dispatchSnackBar = (type, message, open, displayMessage) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };

  const contactUsObject = {
    full_name: name,
    email: email,
    phone: phoneNumber,
    msg: msg
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleClick = async () => {
    if (!validator.isEmail(email)) {
      setEmailError("Please enter your email address");
      setNameError("");
      setPhoneNumberError("");
    } else if (!phoneNumber) {
      setPhoneNumberError("Please enter your phone");
      setEmailError("");
      setNameError("");
    } else if (!name) {
      setNameError("Please enter your name");
      setEmailError("");
      setPhoneNumberError("");
    } else {
      setEmailError("");
      setNameError("");
      setPhoneNumberError("");
      dispatch(postContactUs(contactUsObject));
      handleClose();
      dispatchSnackBar("success", "Your Details has been submitted");
      {brochure.data && window.open(brochure.data.attributes.url, "_blank")};
    }
  };

  return (
    <>
      <BoxStyle>
        <ImageStyle src={SubscribeImage} alt="" />
        <AiOutlineClose
          size={20}
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
        <UpdatesBox>
          <UpdatesContainer>
            <HeadingMain>{parameter}</HeadingMain>
            <ParaMain>
            {para}
            </ParaMain>
            {emailError && <EmailError>{emailError} </EmailError>}
            {nameError && <EmailError>{nameError}</EmailError>}
            {phoneNumberError && <EmailError>{phoneNumberError}</EmailError>}
            <InputContainerBrochuer>
              <InputEmail
                placeholder="Name"
                type="text"
                id="name"
                name="name"
                onChange={handleNameChange}
              />
              <InputEmail
                filterprop={emailError ? "error" : ""}
                placeholder="Email"
                type="text"
                id="email"
                name="email"
                onChange={handleChange}
                value={email}
              />
              <InputEmail
                placeholder="Phone number"
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                onChange={handlePhoneNumberChange}
                value={phoneNumber}
                required
              />
              <SendButton onClick={handleClick}>{button}</SendButton>
            </InputContainerBrochuer>
            <InLine>
            <BsFillEyeFill size={20} color="grey" /> <ParaDown>
            Modern Living and Business Partners use your data for marketing
            purposes. You can unsubscribe at any time free of charge
          </ParaDown>
          </InLine>
          </UpdatesContainer>
        </UpdatesBox>
      </BoxStyle>
    </>
  );
};

export default BrochuerPopUp;
