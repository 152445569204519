import { createSlice } from '@reduxjs/toolkit';

const developerSlice = createSlice({
    name: 'Developer',
    initialState: {
        developerData: [],
        isLoading: true,
        error: false,
       },
    reducers: {
         hasError: (state, action) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        getDeveloper(state, action) {
            console.log(action.payload)
        },
        setDeveloper(state, action) {        
            state.developerDataData = action.payload;
            state.isLoading = false;
        },
    },
});



export const { setDeveloper, hasError, getDeveloper} = developerSlice.actions;

export default developerSlice.reducer;