import React from 'react'
import { PrivacyDesc, PrivacyDiv, Privacyheader } from './PrivatePolicy.styled'

const Privacy = () => {
  return (
    <PrivacyDiv>
        <Privacyheader>Privacy Policy</Privacyheader>
        <br/>
        <PrivacyDesc>Updated at 2022-11-07</PrivacyDesc>
        <br/>
        <PrivacyDesc>Modern Living (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy explains how your personalinformation is collected, used, and disclosed by Modern Living.</PrivacyDesc>
        <br/>
        <PrivacyDesc>This Privacy Policy applies to our website, and its associated subdomains (collectively, our “Service”) alongside our application,Modern Living. By accessing or using our Service, you signify that you have read, understood, and agree to our collection,storage, use, and disclosure of your personal information as described in this Privacy Policy and our Terms of Service.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Definitions and key terms</Privacyheader>
        <br/>
        <PrivacyDesc>To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictlydefined as:
            <li>Cookie: small amount of data generated by a website and saved by your web browser. It is used to identify your browser,provide analytics, remember information about you such as your language preference or login information.</li>
            <li>Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers to Modern Living Real Estate Brokers LLC,Office C101 The Opus Tower Business Bay Dubai, UAE that is responsible for your information under this Privacy Policy.</li>
            <li>Country: where Modern Living or the owners/founders of Modern Living are based, in this case is United Arab Emiratesl</li>
            <li>Customer: refers to the company, organization or person that signs up to use the Modern Living Service to manage therelationships with your consumers or service users.</li>
            <li>Device: any internet connected device such as a phone, tablet, computer or any other device that can be used to visitModern Living and use the services.</li>
            <li>IP address: Every device connected to the Internet is assigned a number known as an Internet protocol (IP) address. These numbers are usually assigned in geographic blocks. An IP address can often be used to identify the location fromwhich a device is connecting to the Internet.</li>
            <li>Personnel: refers to those individuals who are employed by Modern Living or are under contract to perform a service onbehalf of one of the parties.</li>
            <li>Personal Data: any information that directly, indirectly, or in connection with other information — including a personalidentification number — allows for the identification or identifiability of a natural person.</li>
            <li>Service: refers to the service provided by Modern Living as described in the relative terms (if available) and on thisplatform.</li>
            <li>Third-party service: refers to advertisers, contest sponsors, promotional and marketing partners, and others who provideour content or whose products or services we think may interest you.</li>
            <li>Website: Modern Living's site, which can be accessed via this URL: modernliving.ae</li>
            <li>You: a person or entity that is registered with Modern Living to use the Services.</li>

        </PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Information automatically collected</Privacyheader>
        <br/>
        <PrivacyDesc>There is some information like your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our platform. This information may be used to connect your computer to the Internet. Other information collected automatically could be a login, e-mail address, password, computer and connection information such asbrowser plug-in types and versions and time zone setting, operating systems and platforms, purchase history, (we sometimes aggregate with similar information from other Users), the full Uniform Resource Locator (URL) clickstream to, through and fromour Website that may include date and time; cookie number; parts of the site you viewed or searched for; and the phone numberyou used to call our Customer Services. We may also use browser data such as cookies, Flash cookies (also known as Flash Local Shared Objects) or similar data on certain parts of our Website for fraud prevention and other purposes. During your visits,we may use software tools such as JavaScript to measure and collect session information including page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), and
methods used to browse away from the page. We may also collect technical information to help us identify your device for fraud prevention and diagnostic purposes.</PrivacyDesc>
        <br/>
        <PrivacyDesc>We automatically collect certain information when you visit, use or navigate the platform. This information does not reveal yourspecific identity (like your name or contact information) but may include device and usage information, such as your IP address,browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location,information about who and when you use our and other technical information. This information is primarily needed to maintainthe security and operation of our platform, and for our internal analytics and reporting purposes.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Personnel</Privacyheader>
        <br/>
        <PrivacyDesc>If you are a Modern Living worker or applicant, we collect information you voluntarily provide to us. We use the informationcollected for Human Resources purposes in order to administer benefits to workers and screen applicants.</PrivacyDesc>
        <br/>
        <PrivacyDesc>You may contact us in order to (1) update or correct your information, (2) change your preferences with respect tocommunications and other information you receive from us, or (3) receive a record of the information we have relating to you.Such updates, corrections, changes and deletions will have no effect on other information that we maintain, or information thatwe have provided to third parties in accordance with this Privacy Policy prior to such update, correction, change or deletion.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Sale of Business</Privacyheader>
        <br/>
        <PrivacyDesc>We reserve the right to transfer information to a third party in the event of a sale, merger or other transfer of all or substantiallyall of the assets of Modern Living or any of its Corporate Affiliates (as defined herein), or that portion of Modern Living or any ofits Corporate Affiliates to which the Service relates, or in the event that we discontinue our business or file a petition or havefiled against us a petition in bankruptcy, reorganization or similar proceeding, provided that the third party agrees to adhere tothe terms of this Privacy Policy.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Affiliates</Privacyheader>
        <br/>
        <PrivacyDesc>We may disclose information (including personal information) about you to our Corporate Affiliates. For purposes of this PrivacyPolicy, "Corporate Affiliate" means any person or entity which directly or indirectly controls, is controlled by or is under commoncontrol with Modern Living, whether by ownership or otherwise. Any information relating to you that we provide to our CorporateAffiliates will be treated by those Corporate Affiliates in accordance with the terms of this Privacy Policy.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Governing Law</Privacyheader>
        <br/>
        <PrivacyDesc>This Privacy Policy is governed by the laws of United Arab Emirates without regard to its conflict of laws provision. You consentto the exclusive jurisdiction of the courts in connection with any action or dispute arising between the parties under or inconnection with this Privacy Policy except for those individuals who may have rights to make claims under Privacy Shield, or theSwiss-US framework.</PrivacyDesc>
        <br/>
        <PrivacyDesc>The laws of United Arab Emirates, excluding its conflicts of law rules, shall govern this Agreement and your use of the website.Your use of the website may also be subject to other local, state, national, or international laws.</PrivacyDesc>
        <br/>
        <PrivacyDesc>By using Modern Living or contacting us directly, you signify your acceptance of this Privacy Policy. If you do not agree to thisPrivacy Policy, you should not engage with our website, or use our services. Continued use of the website, direct engagementwith us, or following the posting of changes to this Privacy Policy that do not significantly affect the use or disclosure of your
personal information will mean that you accept those changes.</PrivacyDesc>
<br/>
        <br/>
        <br/>
        <Privacyheader>Your Consent</Privacyheader>
        <br/>
        <PrivacyDesc>We've updated our Privacy Policy to provide you with complete transparency into what is being set when you visit our site andhow it's being used. By using our website, registering an account, or making a purchase, you hereby consent to our PrivacyPolicy and agree to its terms.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Links to Other Websites</Privacyheader>
        <br/>
        <PrivacyDesc>This Privacy Policy applies only to the Services. The Services may contain links to other websites not operated or controlled byModern Living. We are not responsible for the content, accuracy or opinions expressed in such websites, and such websites arenot investigated, monitored or checked for accuracy or completeness by us. Please remember that when you use a link to gofrom the Services to another website, our Privacy Policy is no longer in effect. Your browsing and interaction on any otherwebsite, including those that have a link on our platform, is subject to that website’s own rules and policies. Such third partiesmay use their own cookies or other methods to collect information about you.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Cookies</Privacyheader>
        <br/>
        <PrivacyDesc>Modern Living uses "Cookies" to identify the areas of our website that you have visited. A Cookie is a small piece of data storedon your computer or mobile device by your web browser. We use Cookies to enhance the performance and functionality of ourwebsite but are non-essential to their use. However, without these cookies, certain functionality like videos may becomeunavailable or you would be required to enter your login details every time you visit the website as we would not be able toremember that you had logged in previously. Most web browsers can be set to disable the use of Cookies. However, if youdisable Cookies, you may not be able to access functionality on our website correctly or at all. We never place PersonallyIdentifiable Information in Cookies.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Blocking and disabling cookies and similar technologies</Privacyheader>
        <br/>
        <PrivacyDesc>Wherever you're located you may also set your browser to block cookies and similar technologies, but this action may block ouressential cookies and prevent our website from functioning properly, and you may not be able to fully utilize all of its featuresand services. You should also be aware that you may also lose some saved information (e.g. saved login details, sitepreferences) if you block cookies on your browser. Different browsers make different controls available to you. Disabling acookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within yourbrowser, you should visit your browser's help menu for more information.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Changes To Our Privacy Policy</Privacyheader>
        <br/>
        <PrivacyDesc>We may change our Service and policies, and we may need to make changes to this Privacy Policy so that they accurately reflect our Service and policies. Unless otherwise required by law, we will notify you (for example, through our Service) beforewe make changes to this Privacy Policy and give you an opportunity to review them before they go into effect. Then, if youcontinue to use the Service, you will be bound by the updated Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you can delete your account.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Third-Party Services</Privacyheader>
        <br/>
        <PrivacyDesc>We may display, include or make available third-party content (including data, information, applications and other productsservices) or provide links to third-party websites or services ("Third- Party Services").</PrivacyDesc>
        <br/>
        <PrivacyDesc>You acknowledge and agree that Modern Living shall not be responsible for any Third-Party Services, including their accuracy,completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. Modern Livingdoes not assume and shall not have any liability or responsibility to you or any other person or entity for any Third-PartyServices.</PrivacyDesc>
        <br/>
        <PrivacyDesc>Third-Party Services and links thereto are provided solely as a convenience to you and you access and use them entirely atyour own risk and subject to such third parties' terms and conditions.</PrivacyDesc>
        <br/>
        <br/>
        <br/>
        <Privacyheader>Contact Us</Privacyheader>
        <br/>.
        <PrivacyDesc>Don't hesitate to <a href='/contactus' style={{textDecoration:'underline'}}>contact us</a> if you have any questions.</PrivacyDesc>
    </PrivacyDiv>
    
  )
}

export default Privacy