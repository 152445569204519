import axios from "axios";
import API_URL from "../constant";


export function requestGetProperties(data) {
  const pageSize = 10;
  const url = `${API_URL}properties?${data.community ? `filters[area][area][$contains]=${data.community}&`:''}${data.type ? `filters[listing_category]=${data.type}&`:''}${data.unittype && data.unitype !== 'All' ? `filters[unit_type]=${data.unittype}&`:''}${data.price.lower ? `filters[price][$gte]=${data.price.lower}&`:''}${data.price.higher?`filters[price][$lte]=${data.price.higher}&`:''}${data.bed ? `filters[bedrooms]${data.bed>2?'[$gte]=3&filters[bedrooms][$ne]=Studio':`=${data.bed}&`}` : ""}${data.filter ? `filters[tags][value]=${data.filter}`:''}&sort=createdAt:DESC&populate=*&pagination[page]=${data.page?data.page:1}&pagination[pageSize]=${pageSize}`;
  return axios.request({
    method: "get",
    url: url,
  });
}

export function requestGetFeatureProperties() {
  return axios.request({
    method: "get",
    url: API_URL + "properties?populate=*&filters[featured]=true",
  });
}

export function requestGetPropertiesDetail(data) {
  const id = data;
  return axios.request({
    method: "get",
    url: `${API_URL}properties/${id}?populate=*`,
  });
}
