import React , { useState } from 'react'
import Visit1 from "../../assets/images/virtualvisit.svg";
import Visit2 from "../../assets/images/virtualvisit2.svg";
import Arrow from "../../assets/images/Arrow.svg";
import Arrow2 from "../../assets/images/Arrow2.svg";
import { Link } from "react-router-dom";
import {
  PropertyCardDetailWrapper,
  PropertyCardDetailContainer,
  PropertyCardDetailText,
  PropertyCardDetailText2,
  PropertyCardButton,
  PropertyIconVisit,
  PropertyIconExplore,
  PropertyImage,
  CardTitle,
  CardSubText,
  PriceText,
  // SinglePropertyCard,
  PropertyCardContent,
  ButtonContainer,
  SlideIconRight,
  SlideIconLeft,
} from "./PropertyListing.styled";
import { useSelector } from 'react-redux';
import { BiChevronRight, BiChevronLeft} from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion"
import { useTranslation } from 'react-i18next';

const preloadImages = (images) => {
  images.forEach((image) => {
    const img = new Image();
    img.src = image.attributes.url;
  });
};

// Call the preloadImages function before rendering the PropertyCard component

export const PropertyCard = ({property}) => {
  const [visit, setVisit] = useState(Visit1);
  const [explore, setExplore] = useState(Arrow2);
  const [count, setCount] = useState(0)
  const [position, setPosition] = useState('')
  const {convertValue,currency, convertUnit, unit} = useSelector((state) => state.unitConversion);
  const area = Math.round(property.attributes.size*convertUnit);
  const convertedCurrency = Math.round(property.attributes.price*convertValue);
  const images = property.attributes.image.data;
  preloadImages(property.attributes.image.data);
  const {t} = useTranslation()
  return (
    <>
      <PropertyCardContent>
        {property.attributes && property.attributes.title && <CardTitle>
          {property.attributes.title}
        </CardTitle>}
        {property.attributes && property.attributes.building_name && <CardSubText>
          {property.attributes.building_name}
        </CardSubText>}
        <PropertyCardDetailWrapper>
          <PropertyCardDetailContainer>
            <PropertyCardDetailText>{t('Location')}:</PropertyCardDetailText>
            {(property.attributes.area.data || property.attributes.location.data) && 
            <PropertyCardDetailText2>
            {property.attributes.area.data.attributes.area || property.attributes.location.data.attributes.area }, Dubai
            </PropertyCardDetailText2>}
          </PropertyCardDetailContainer>
          <PropertyCardDetailContainer>
            <PropertyCardDetailText>{t('Size')}:</PropertyCardDetailText>
            <PropertyCardDetailText2>{area.toLocaleString()} {unit}</PropertyCardDetailText2>
          </PropertyCardDetailContainer>
          <PropertyCardDetailContainer>
            <PropertyCardDetailText>{t('Bedrooms')}:</PropertyCardDetailText>
            {property.attributes && property.attributes.bedrooms && <PropertyCardDetailText2>{property.attributes.bedrooms}</PropertyCardDetailText2>}
          </PropertyCardDetailContainer>
        </PropertyCardDetailWrapper>
        {property.attributes.image && property.attributes.image.data && 
        <div style={{position:'relative'}}>
          <Link to={`/properties/detail/${property.id}`}>
          <AnimatePresence mode='wait'>
          <motion.div
            key = {images[count].attributes.url}
             initial={{ x: position}} 
      animate={{ x:0}}
      exit={{ opacity:0 }}
      >
        <PropertyImage img = {images[count].attributes.url} >
        </PropertyImage>
        </motion.div>
        </AnimatePresence>
        </Link>
        <SlideIconRight disabled={count===property.attributes.image.data.length-1} onClick={()=>{setCount(count+1); setPosition('100%')}}> <BiChevronRight size={25} /></SlideIconRight>
        <SlideIconLeft disabled={count===0} onClick={()=>{setCount(count-1); setPosition('-100%')}}> <BiChevronLeft size={25} /></SlideIconLeft>
        </div>
        }
        <PriceText>
          {currency} {convertedCurrency.toLocaleString()}
        </PriceText>
      </PropertyCardContent>
      <ButtonContainer>
        <PropertyCardButton onMouseEnter={()=>setVisit(Visit2)} onMouseLeave={()=>setVisit(Visit1)} style={{ borderRight: "1px solid", pointerEvents:'none', opacity:'0.5'}}>
          <PropertyIconVisit src={visit} alt="visit" />
          VIRTUAL VISIT
        </PropertyCardButton> 
        <PropertyCardButton onMouseEnter={()=>setExplore(Arrow)} onMouseLeave={()=>setExplore(Arrow2)}>
        <Link to={`/properties/detail/${property.id}`}>
          EXPLORE
          <PropertyIconExplore src={explore} alt="arrow" />
        </Link>
        </PropertyCardButton>
      </ButtonContainer>
    </>
  );
};
