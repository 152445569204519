import * as React from "react";
import { useEffect} from "react";
import PaginationItem from "@mui/material/PaginationItem";
import Pagination from "@mui/material/Pagination";
import { PaginationCard } from "./propertylisting.styled";
import { getIndex } from "../../redux/slices/propertiesSlices/getProperties";
import { useDispatch, useSelector } from "react-redux";
import './styles.css'
import { setSearch } from "../../redux/slices/herosearch/herosearchSlice";

export const PaginationOutlined = ({scrollTop, value}) => {
  const [page, setPage] = React.useState(1);
  const {type, community, unittype, beds, price} = useSelector(state=>state.herosearch)
  const {propertiesMeta} = useSelector(state=>state.properties)
  const {projectsMeta} = useSelector(state=>state.project)
  const dispatch = useDispatch();
  const handleChange = (event, value) => {
    setPage(value);
  };
  
  useEffect(()=>{
    dispatch(getIndex(1));
  },[dispatch])

  useEffect(()=>{
    const searchObject = {
      type:type,
      community:community,
      unittype:unittype,
      bed:beds,
      price:price,
      page:page,
    }
    dispatch(setSearch(searchObject));
    scrollTop();
  },[page, beds, community, dispatch, price, type, unittype])

  return (
      <PaginationCard>
      <Pagination
        count={value === 'property' ? propertiesMeta.pagination?propertiesMeta.pagination.pageCount:1 : projectsMeta.pagination?projectsMeta.pagination.pageCount:1}
        page={page}
        onChange={handleChange}
        hidePrevButton
        hideNextButton
        renderItem={(item) => (
          <PaginationItem
              {...item}
          />
      )}
      />
      </PaginationCard>
  );
};
