import React, { useState } from 'react'
import logo from '../../assets/images/Logo.svg'
import FacebookIcon from '../../assets/images/FacebookIcon.svg'
import YoutubeIcon from '../../assets/images/YoutubeIcon.svg'
import LinkedinIcon from '../../assets/images/LinkedinIcon.svg'
import InstaIcon from '../../assets/images/InstaIcon.svg'
import { AbsoluteDiv, DownToggleIcon, DropDownDiv, FooterMobileContainer, FooterSubmitButton, FooterTerms, InputText, MobileFooterMenuText, myText, SecFooterText } from './Footer.styled'
import './Footer.css'
import { useDispatch } from 'react-redux'
import { showSnackBar } from '../../redux/slices/snackbarSlice/snackbarSlice'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
const FooterMobile = ({width}) => {
  const [email, setEmail] = useState('');
  const [select, setSelect] = useState('')
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const snackbarObject = {
    type: '',
    message: '',
    open: false,
  };
  const dispatchSnackBar = (type, message, open) => {
    snackbarObject.type = type;
    snackbarObject.message = message;
    snackbarObject.open = open;
    dispatch(showSnackBar(snackbarObject));
  };
  const handleContactUs = (e) => {
    e.stopPropagation();
    if (email === '') {
      dispatchSnackBar('error', 'Please enter Email', true);
    }
    else {
      dispatchSnackBar('success', 'Your information is submitted')
      setEmail('');
    }
}; 
  return (
    <section style={{ height: 'auto', backgroundColor: '#12284C', padding: '3%' }}>
      <img src={logo} alt='logo' style={{ width: '318px', height: '31px', margin: 'auto', display: 'block' }} />
      <FooterMobileContainer>
        <DropDownDiv >
        <MobileFooterMenuText onClick={()=>setSelect(select==='menu'?'':'menu')}>MENU <DownToggleIcon animate={select==='menu'}/></MobileFooterMenuText>
        <AbsoluteDiv display={select==='menu'}>
        <Link to = '/properties/buy'> <Typography sx={{
              ...myText,
            }} >
              {t('Properties')}
              
            </Typography></Link>
            <Link to = '/projects/luxury'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              PROJECTS
              
            </Typography>
            </Link>
            <Link to = '/realestate'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              {t('Business Services')}
              
            </Typography>
            </Link>
            <Link to = '/about'>
            <Typography sx={{
              ...myText,
            }} className='footer_menu_text'>
              {t('Company')}
              
            </Typography>
            </Link>
            <Link to = '/press_release'>
            <Typography sx={{
              ...myText,
            }}>
              {t('Press Center')}
              
            </Typography>
            </Link>
            </AbsoluteDiv>
            </DropDownDiv>
            <DropDownDiv onMouseLeave={()=>setSelect('')}>
        <MobileFooterMenuText onClick={()=>setSelect(select==='contact' ? '' : 'contact')}>CONTACT<DownToggleIcon animate={select==='contact'}/></MobileFooterMenuText>
        <AbsoluteDiv display={select==='contact'}>
          <div>
        <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {('Address')}
            </Typography>
            <Typography sx={{
              marginTop:'10px',
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              Office 101 C, The Opus, Business Bay, Dubai - UAE
            </Typography>
            </div>
            <div>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {('Telephone')}
            </Typography>
            <a href='http://wa.me/971581380279'><Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              +971581380279
            </Typography></a>
            </div>
            <div>
            <a href='mailto:info@modernliving.ae'><Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              {('Email')}
            </Typography></a>
            <Typography sx={{
              fontFamily: 'Montserrat',
              fontStyle: 'normal',
              fontWeight: '300',
              fontSize: '14px',
              lineHeight: '17px',
              color: '#FFFFFF'
            }}>
              info@modernliving.ae
            </Typography>
            </div>
        </AbsoluteDiv>
        </DropDownDiv>
      </FooterMobileContainer>
      <FooterMobileContainer>
        <div className='get_in_touch'>
        {width<900 && <MobileFooterMenuText>{t('GET IN TOUCH')}</MobileFooterMenuText>}
          <SecFooterText>{t('Subscribe to our newsletter')}</SecFooterText>
          <div className='footer_submit'>
            <InputText value={email} placeholder={t('YOUR EMAIL')} onChange={(e)=>setEmail(e.target.value)}/>
            <FooterSubmitButton onClick={(e) => handleContactUs(e)}>{t('SUBMIT')}</FooterSubmitButton>
          </div>
          </div>
          <div className='social_join'>
          <SecFooterText>{t('Join us on social media')}</SecFooterText>
          <div className='socialmediaicons'>
          <a href='https://www.facebook.com/modernlivingrealestatedubai'><img src={FacebookIcon} alt='F' className='scicon'/></a>
              <a href='https://www.linkedin.com/company/modern-living-real-estate-dubai'><img src={LinkedinIcon} alt='F' className='scicon'/></a>
              <a href='https://www.youtube.com/@modernlivingrealestatedubai'><img src={YoutubeIcon} alt='F' className='scicon'/></a>
              <a href='https://www.instagram.com/modernliving.ae/?hl=en'><img src={InstaIcon} alt='F' className='scicon'/></a>
            </div>
          </div>
      </FooterMobileContainer>
      <FooterTerms>{t('Terms & Conditions')} / <a href='privacy'>{t('Privacy Policy')}</a> / {t('Sitemap  © 2022. Modern Living. All rights reserved.')} </FooterTerms>
    </section>
  )
}

export default FooterMobile