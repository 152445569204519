import { Skeleton } from "@mui/material";
import React from "react";
import {
  PodcastContainer,
  PodcastImg,
  PodcastHeaderText,
  PodcastButton,
  PodcastContentContainer,
} from "./PressCenter.styled";
const MarketUpdate = ({ data, open, close, download , index }) => {
  return (
    <PodcastContainer>
      {data.attributes.featured_image.data.attributes.url && <PodcastImg img={data.attributes.featured_image.data.attributes.url}>
      </PodcastImg>}
      <PodcastContentContainer>
        <PodcastHeaderText height={data.attributes.title}>
          {data.attributes.title ? (
            data.attributes.title
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height="100%"
            />
          )}
        </PodcastHeaderText>
        <div style={{display:'flex', gap:'10px'}}>
        <PodcastButton onClick={()=>{open(); download(index)}}>Download</PodcastButton>
        <a href={data.attributes.youtube_link}><PodcastButton>Youtube</PodcastButton></a>
        </div>
      </PodcastContentContainer>
    </PodcastContainer>
  );
};

export default MarketUpdate;
