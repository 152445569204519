import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "project",
  initialState: {
    projectData: [],
    projectsMeta: {},
    isLoading: false,
    error: '',
    id: 0,
    projectDetail: {},
  },
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },

    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },

    getProjects(state, action) {},

    setProjectsContent(state, action) {
      const projectData = action.payload;
      state.projectData = projectData;
    },

    setProjectsMeta(state, action) {
      const projectMeta = action.payload;
      state.projectMeta = projectMeta;
    },

    getProjectsDetail(state, action) {
      state.id = action.payload
    },
    setProjectDetail(state, action){
      const projectDetail = action.payload
      state.projectDetail = projectDetail
    },
    getProjectSearch(state,action){

    }
  },
});

export const { setProjectsContent, getProjects, hasError, setProjectsMeta, getProjectsDetail, setProjectDetail} =
  projectSlice.actions;

export default projectSlice.reducer;
