import React, { useState } from "react";
import {
  CallWhatsappBookViewButtonContainer,
  CallWhatsappButtonConatiner,
  HeadingDiv,
  HeadingPaddingProperty,
  MainButton,
  MainButtonTwo,
  MainHeading,
  MainPropertyContainer,
  QRImage,
  QRVirtual,
  VirtualImageSmall,
  VirtualImageWriting,
  VirtualVisitMainContainer,
  WhatsappButton,
} from "../ProjectDetail/projects.styled";
import { scroller } from "react-scroll";
import {
  BreadCrumbAndDetailInfoDiv,
  BreadcrumbsContainer,
} from "./PropertyDetail.styled";
import { BreadcrumbsComponent } from "./BreadcrumbsComponent";
import vi from "../../assets/images/virtualvisit.png";

export const PropertyHeadingAndButtons = ({ details }) => {
  const handleClick = () => {
    scroller.scrollTo("BookView", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  
  if (details && details.attributes && details.attributes.qrCode && details.attributes.qrCode.data && details.attributes.qrCode.data.attributes.url) {
    var qrCode = details.attributes.qrCode.data.attributes.url;
  }

  if (details && details.attributes && details.attributes.virtual_visit) {
    var virtualVisit = details.attributes.virtual_visit;
  }

  const [width, setWidth] = useState(window.innerWidth);

  return (
    <BreadCrumbAndDetailInfoDiv>
      <BreadcrumbsContainer>
        {details && <BreadcrumbsComponent details={details} />}
        {width < 1500 && width > 1000 && (
          <QRVirtual>
            {qrCode && <QRImage src={qrCode} alt=" " />}
            {virtualVisit && (
              <VirtualVisitMainContainer>
                <VirtualImageSmall src={vi} alt="" />
                <VirtualImageWriting>Virtual Visit</VirtualImageWriting>
              </VirtualVisitMainContainer>
            )}
          </QRVirtual>
        )}
      </BreadcrumbsContainer>
      <HeadingPaddingProperty>
        <HeadingDiv>
          {details && details.attributes && details.attributes.title && (
            <MainHeading>
              {details.attributes.title.charAt(0) +
                details.attributes.title.slice(1).toLowerCase()}
            </MainHeading>
          )}
        </HeadingDiv>
        <MainPropertyContainer>
          { width > 1500 && 
            <QRVirtual>
              {qrCode && <QRImage src={qrCode} alt=" " />}
              {virtualVisit && (
                <VirtualVisitMainContainer>
                  <VirtualImageSmall src={vi} alt="" />
                  <VirtualImageWriting>Virtual Visit</VirtualImageWriting>
                </VirtualVisitMainContainer>
              )}
            </QRVirtual>}
          <CallWhatsappBookViewButtonContainer>
            <CallWhatsappButtonConatiner>
              <MainButton href="tel:+971581830279">Call</MainButton>
              <WhatsappButton number="+971581830279">Whatsapp</WhatsappButton>
            </CallWhatsappButtonConatiner>
            <MainButtonTwo onClick={handleClick}>Book View</MainButtonTwo>
          </CallWhatsappBookViewButtonContainer>
          {width < 1000 && (qrCode || virtualVisit) ? (
            <QRVirtual>
              {qrCode && <QRImage src={qrCode} alt=" " />}
              {virtualVisit && (
                <VirtualVisitMainContainer>
                  <VirtualImageSmall src={vi} alt="" />
                  <VirtualImageWriting>Virtual Visit</VirtualImageWriting>
                </VirtualVisitMainContainer>
              )}
            </QRVirtual>
          ) : null}
        </MainPropertyContainer>
      </HeadingPaddingProperty>
    </BreadCrumbAndDetailInfoDiv>
  );
};
