import React, { useEffect, useRef, useState } from "react";
import { Price } from "../propertiesListing/Price";
import { UnitTypeButton } from "../propertiesListing/UnitTypeButton";
import { SearchButton, SearchButtonDiv } from "./SearchProject.styled";
import Calender_Icon from "../../assets/images/calender.jpg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Address,
  BoxDiv,
  BoxField,
  BoxText,
  CalendarIcon,
  InputField,
  LocationIcon,
} from "../propertiesListing/propertylisting.styled";
import { useDispatch, useSelector } from "react-redux";
import {
  setProjectSearch,
  setProjectSearchContent,
} from "../../redux/slices/heroProjectSearch/heroProjectSearchSlices";
import { getLocation } from "../../redux/slices/location/locationSlice";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { getDeveloper } from "../../redux/slices/developers/developerSlice";
import { useNavigate, useParams } from "react-router-dom";

const CustomHeader = ({ date, decreaseYear, increaseYear }) => {
  return (
    <div className="calender-custom-header">
      <FaChevronLeft className="prev-icon" onClick={decreaseYear} />
      <div className="header-text">{date.getFullYear()}</div>
      <FaChevronRight className="next-icon" onClick={increaseYear} />
    </div>
  );
};

export const SearchButtons = ({
  background,
  border,
  color,
  menuback,
  menucolor,
  menuitemcolor,
  menuitembackgroundhover,
  arrow,
  activecolor,
}) => {
  const data = useSelector((state) => state);
  const dropdownRef = useRef(null);
  const devdropdownRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const calendarDropdownRef = useRef(null);
  const [community, setCommunity] = useState(
    data.heroprojectsearch.community ? data.heroprojectsearch.community : ""
  );
  const [developer, setDeveloper] = useState(
    data.heroprojectsearch.developer ? data.heroprojectsearch.developer : ""
  );

  const [areaActive, setAreaActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [calendarActive, setCalendarActive] = useState(false);
  const [developerActive, setDeveloperActive] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const HandleSearch = (e) => {
    const searchObject = {
      type: data.heroprojectsearch.type,
      community: data.heroprojectsearch.community
        ? data.heroprojectsearch.community
        : "",
      developer: data.heroprojectsearch.developer
        ? data.heroprojectsearch.developer
        : "",
      date: data.heroprojectsearch.date ? data.heroprojectsearch.date : "",
      unittype: data.heroprojectsearch.unittype
        ? data.heroprojectsearch.unittype
        : "",
      bed: data.heroprojectsearch.beds ? data.heroprojectsearch.beds : "",
      price: data.heroprojectsearch.price ? data.heroprojectsearch.price : "",
      page: 1,
    };
    console.log(searchObject)
    const currentURL = window.location.href;
    const searchTerm = "search";
    if (!currentURL.includes(searchTerm)) {
      navigate(`/projects/search/${params.id}`);
    } 
    dispatch(setProjectSearch(searchObject)); 
  };

  const location = useSelector((state) => state.location);
  const developerData = useSelector((state) => state.developer);
  const handleInput = (event, setState) => {
    const newValue = event.target.value;
    if (newValue.trim() === "") {
      setState("");
    } else {
      setState(newValue);
    }
  };

  const handleInputChange = (value, actionType) => {
    let words = value.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const joinedWords = capitalizedWords.join(" ");
    if (actionType === "community") {
      dispatch(getLocation(joinedWords));
    } else if (actionType === "developer") {
      dispatch(getDeveloper(joinedWords));
    }
  };

  useEffect(() => {
    handleInputChange(community, "community");
    handleInputChange("", "developer");
  }, []);

  useEffect(() => {
    handleInputChange(community, "community");
  }, [community, dispatch]);

  useEffect(() => {
    handleInputChange(developer, "developer");
  }, [developer, dispatch]);

  useEffect(() => {
    dispatch(setProjectSearchContent({ key: "community", value: community }));
  }, [community]);

  useEffect(() => {
    dispatch(setProjectSearchContent({ key: "developer", value: developer }));
  }, [developer]);

  useEffect(() => {
    dispatch(setProjectSearchContent({ key: "date", value: selectedDate }));
  }, [selectedDate]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setAreaActive(false);
    }
    if (
      devdropdownRef.current &&
      !devdropdownRef.current.contains(event.target)
    ) {
      setDeveloperActive(false);
    }
    if (
      calendarDropdownRef.current &&
      !calendarDropdownRef.current.contains(event.target)
    ) {
      setCalendarActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <SearchButtonDiv>
      <div ref={dropdownRef}>
        <BoxField border={border} onClick={() => setAreaActive(true)}>
          <LocationIcon color={arrow} />
          <InputField
            color={menucolor}
            placeholder={t("Enter area or community")}
            onChange={(event) => handleInput(event, setCommunity)}
            value={community}
          />
        </BoxField>
        <BoxDiv
          border={border}
          active={areaActive}
          style={{ borderRadius: "20px", marginTop: "6px" }}
        >
          {location.locationData &&
            (location.locationData.length === 0 ? (
              <BoxText>{t("No Data Found")}</BoxText>
            ) : (
              location.locationData.map((i, id) => {
                return (
                  <BoxText
                    key={id}
                    color={menucolor}
                    hoverback={menuitembackgroundhover}
                    hovercolor={menuitemcolor}
                    onClick={() => {
                      setCommunity(i.attributes.area);
                      setAreaActive(false);
                    }}
                  >
                    {i.attributes.area}
                  </BoxText>
                );
              })
            ))}
        </BoxDiv>
      </div>
      <div ref={devdropdownRef}>
        <BoxField
          onClick={() => setDeveloperActive(true)}
          background={background}
          border={border}
        >
          {/* <Address src={BiBuildingHouse} alt=" " /> */}
          <InputField
            color={menucolor}
            style={{ paddingLeft: "20px" }}
            placeholder={t("Developer Name")}
            onChange={(event) => handleInput(event, setDeveloper)}
            value={developer}
          />
        </BoxField>
        <BoxDiv
          border={border}
          active={developerActive}
          style={{ borderRadius: "20px", marginTop: "6px" }}
        >
          {developerData.developerDataData &&
            (developerData.developerDataData.data.length === 0 ? (
              <BoxText>{t("No Data Found")}</BoxText>
            ) : (
              developerData.developerDataData.data.map((i, id) => {
                return (
                  <BoxText
                    key={id}
                    color={menucolor}
                    hoverback={menuitembackgroundhover}
                    hovercolor={menuitemcolor}
                    onClick={() => {
                      setDeveloper(i.attributes.developer_name);
                      setDeveloperActive(false);
                    }}
                  >
                    {i.attributes.developer_name}
                  </BoxText>
                );
              })
            ))}
        </BoxDiv>
      </div>
      <div ref={calendarDropdownRef}>
        <BoxField
          onClick={() => setCalendarActive(true)}
          background={background}
          border={border}
        >
          <CalendarIcon color={arrow} />
          <InputField
            placeholder={selectedDate === "" ? "Select date" : selectedDate}
            color={menucolor}
            value={`${
              selectedDate &&
              selectedDate.toString().split(" ")[1] +
                selectedDate.toString().split(" ")[3]
            }`}
            readOnly
          />
        </BoxField>
        {/* Render the date picker component here */}
        <BoxDiv
          // border={border}
          active={calendarActive}
          style={{ borderRadius: "20px", marginTop: "6px" }}
        >
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="MM/yyyy"
            inline
            showMonthYearPicker
            renderCustomHeader={({ date, decreaseYear, increaseYear }) => (
              <CustomHeader
                date={date}
                decreaseYear={decreaseYear}
                increaseYear={increaseYear}
              />
            )}
            minDate={new Date(2000, 0)} // Set the minimum selectable year
            maxDate={new Date(2030, 11)}
            excludeDates={[
              1661990400000, 1664582400000, 1667260800000, 1672531200000,
            ]}
          />
        </BoxDiv>
      </div>
      <UnitTypeButton
        data="project"
        border={border}
        color={color}
        arrow={arrow}
        menuback={menuback}
        menucolor={menucolor}
        menuitemcolor={menuitemcolor}
        menuitembackgroundhover={menuitembackgroundhover}
        activecolor={activecolor}
      />
      <Price
        data="project"
        border={border}
        color={color}
        arrow={arrow}
        menuback={menuback}
        menucolor={menucolor}
        menuitemcolor={menuitemcolor}
        menuitembackgroundhover={menuitembackgroundhover}
        activecolor={activecolor}
      />
      <SearchButton
        onClick={HandleSearch}
        bg={menuitembackgroundhover}
        color={menuitemcolor}
      >
        Search
      </SearchButton>
    </SearchButtonDiv>
  );
};
