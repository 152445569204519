import axios from "axios";

import API_URL from "../constant";
// {{dev_url}}api/projects?populate=*&filters[unit_type]=Apartment&filters[location][area]=Marina&filters[available_units][rooms]=3&filters[available_units][price][$gte]=&filters[available_units][price][$lte]


export function requestGetProjects(data) {
  const formatDate = (date) => {
    if (date) {
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${year}-${month}-01`;
    }
    return '';
  };
  
  const formattedDate = formatDate(data.date); // Call formatDate function to format the date
  
  const url = `${API_URL}projects?${data.community ? `filters[location][area][$contains]=${data.community}&`:''}${data.developer ? `filters[developer][developer_name][$contains]=${data.developer}&`:''}${data.date ? `filters[completion_date][$lt]=${formattedDate}&`:''}${data.type ? `filters[category]=${data.type}&`:''}${data.unittype ? `filters[unit_type]=${data.unittype}&`:''}${data.price.lower ? `filters[available_units][[price][$gte]=${data.price.lower}&`:''}${data.price.higher?`filters[available_units][[price][$lte]=${data.price.higher}&`:''}populate=*&pagination[page]=${data.page?data.page:1}&pagination[pageSize]=10`;
  return axios.request({
    method: "get",
    url: url,
  });
}

export function requestGetFeatureProjects(data) {
  return axios.request({
    method: "get",
    url:
      API_URL +
      `projects?populate=*&filters[is_featured]=true&filters[category]=${data.toLowerCase()}`
});
}

export function requestGetProjectDetail(data) {
  const id = data;
  return axios.request({
    method: "get",
    url: `${API_URL}projects/${id}?populate=*`,
  });
}
